import { type Component, createSignal, Show, createMemo } from 'solid-js';
import { IconChevronLeft, IconChevronRight } from '~/components/ui/Icons';
import { useLockBodyScroll } from '~/hooks/useLockBodyScroll';
import { IconLoader, IconX } from './icons';

const [imageUrl, setImageUrl] = createSignal<string>();
const [images, setImages] = createSignal<string[]>([]);
const [currentIndex, setCurrentIndex] = createSignal(0);

export const preview = (imageUrl: string) => {
  setImageUrl(imageUrl);
  setImages([imageUrl]);
  setCurrentIndex(0);
};

export const previewMultiple = (images: string[], currentIndex?: number) => {
  setImages(images);
  setCurrentIndex(currentIndex || 0);
};

export const ImagePreview: Component = () => {
  useLockBodyScroll(() => imageUrl() != null);

  const currentImage = createMemo(() => images()[currentIndex()]);

  const close = () => {
    setImageUrl(undefined);
    setImages([]);
    setCurrentIndex(0);
  };

  const changeIndex = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <Show when={currentImage()}>
      <div class="fixed inset-0 z-50 flex items-center justify-center p-10">
        <div class="absolute inset-0 -z-10 bg-black/80" onClick={close} />
        <button class="absolute right-4 top-3 rounded-full border border-white p-2 text-white hover:bg-white/20" onClick={close}>
          <IconX aria-label="Close" />
        </button>
        <IconLoader class="absolute -z-10 size-10 animate-spin text-white" />
        <Show when={currentIndex() > 0}>
          <IconChevronLeft
            onClick={() => changeIndex(currentIndex() - 1)}
            class="relative left-[-32px] cursor-pointer rounded-full bg-[#2E2E2E] text-white"
          />
        </Show>
        <img class="max-h-full max-w-[1000px] bg-faded" src={currentImage()} />
        <Show when={currentIndex() < images().length - 1}>
          <IconChevronRight
            onClick={() => changeIndex(currentIndex() + 1)}
            class=" relative right-[-32px] cursor-pointer rounded-full bg-[#2E2E2E] text-white"
          />
        </Show>
      </div>
    </Show>
  );
};
